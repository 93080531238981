var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-profile"},[_c('div',{staticClass:"profile mt-1"},[_c('div',{staticClass:"profile-content"},[_c('v-row',{staticClass:"user-info",attrs:{"dense":""}},[(_vm.loading)?_c('div',{staticClass:"loading"},[_c('v-progress-linear',{attrs:{"indeterminate":true}})],1):_c('v-col',{attrs:{"md":"12","sm":"12"}},[_c('v-row',{staticClass:"fill-height",attrs:{"dense":""}},[_c('v-col',{staticClass:"white",attrs:{"md":"4"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"tile":"","flat":""}},[_c('v-card-title',{staticClass:"heading font-weight-bold primary--text"},[_vm._v(" Admin Templates ")]),_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',_vm._l((_vm.tabs),function(item,i){return _c('v-list-item',{key:i,class:{
                        'primary light--text':
                          _vm.activeType && _vm.activeType.type === item.type
                      },on:{"click":function($event){return _vm.setActiveType(i, item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-2",class:[
                            _vm.activeType && _vm.activeType.type === item.type
                              ? 'primary light--text'
                              : 'primary--text'
                          ],domProps:{"textContent":_vm._s(item.title)}})],1)],1)}),1)],1)],1)],1),_c('v-col',{staticClass:"white",attrs:{"grow":""}},[(_vm.activeType)?_c('v-card',{staticClass:"rounded-10",attrs:{"flat":"","min-height":"500"}},[_c('v-card-text',[_c('span',{staticClass:"subtitle-1 font-weight-bold primary--text"},[_vm._v(" "+_vm._s(_vm.activeType.title)+" ")]),_c('br'),_c('small',{staticClass:"caption"},[_vm._v(" "+_vm._s(_vm.activeType.description)+" ")])]),_c('v-card-text',[(_vm.activeType.slots.length > 0)?[_c('p',[_vm._v("Available placeholder:")]),_vm._l((_vm.activeType.slots),function(slot){return _c('v-chip',{key:slot,staticClass:"mr-1 mb-1",attrs:{"depressed":"","label":"","color":"primary"},on:{"click":function($event){return _vm.append(slot)}}},[_vm._v(" "+_vm._s(slot)+" "),_c('v-icon',{attrs:{"small":"","right":""}},[_vm._v("mdi-plus")])],1)})]:_vm._e(),_c('Editor',{attrs:{"placeholder":"Write something... (If empty, will used the default global template.)","min-height":"300"},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}})],2),_c('v-card-actions',{staticClass:"my-5 mr-2"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-5 text-none",attrs:{"color":"primary","loading":_vm.submitting},on:{"click":_vm.saveChanges}},[_vm._v(" Save Changes ")])],1)],1):_c('Empty',{attrs:{"headline":"Select from the left"}})],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }