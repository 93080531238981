import { list_functionality } from '@/services/list-functionality/list-functionality'
import { api_to } from './api'
//Components
import Editor from '@/common/Editor/Editor.vue'

export default {
    name: 'EmailTemplates',
    mixins: [list_functionality],
    components: {
        Editor
    },
    data: () => ({
        paths: [
            { text: 'Settings', disabled: false, route: { name: 'settings' } },
            { text: 'Email Templates', disabled: true, route: null }
        ],
        activeType: null,
        admin_templates: null,
        client_templates: [],
        core_templates: [],
        submitting: false,
        content: '',
        tab: 'admin',
        selectedClientTemplate: {
            id: null,
            schedule_type: 'client-email',
            name: null,
            from: null,
            to: [],
            subject: null,
            contents: null,
            interval_type: 'every_month',
            interval_at: null,
            timezone: 'UTC'
        }
    }),
    mounted() {
        this.$event.$emit('path-change', this.paths)
        this.getTemplates()
        this.getCoreTemplates((data) => {
            if (data.length) {
                this.activeType = data[0] || null
            }
        })
    },
    computed: {
        user() {
            return this.$store.getters.user
        },
        tabs() {
            return this.core_templates
        }
    },
    methods: {
        append(slot) {
            this.content = `${this.content} ${slot}`
        },
        setTab(tab) {
            this.tab = tab
        },
        setActiveType(type, item) {
            this.activeType = item
            this.content = ''
            let index = this.admin_templates.findIndex((i) =>
                i.name.includes(item.type)
            )
            this.content = ~index ? this.admin_templates[index].template.value : null
        },
        saveChanges() {
            if (this.content.trim() === '' || this.content.trim() === '<p></p>') {
                this.appSnackbar('Template cannot be empty', 'error')
                return
            }
            this.submitting = true
            let payload = {
                name: this.activeType.type,
                value: this.content.trim() === '<p></p>' ? '' : this.content
            }
            api_to
                .save_changes(payload)
                .then(({ data }) => {
                    let index = this.admin_templates.findIndex((i) => i.id === data.id)
                    if (~index) {
                        this.admin_templates.splice(index, 1, data)
                        this.appSnackbar('Template saved.')
                    }
                })
                .finally(() => {
                    this.submitting = false
                })
        },
        getTemplates(cb) {
            this.loading = true
            api_to
                .get_email_templates()
                .then(({ data }) => {
                    this.admin_templates = data
                    if (typeof cb === 'function') {
                        cb(data)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getCoreTemplates(cb) {
            this.loading = true
            api_to
                .get_email_core_templates()
                .then(({ data }) => {
                    this.core_templates = data
                    if (typeof cb === 'function') {
                        cb(data)
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        getClientTemplates() {
            this.loading = true
            api_to
                .scheduleClientEmailTemplates()
                .then(({ data }) => {
                    this.client_templates = data.schedule_tasks
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}